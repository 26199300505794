import React, { FC, useState } from "react";
import Button from "../common/Button";
import Modal from "../common/Modal";
import { AgencyMenuItem } from "../../common/type";
import Alert from "../common/Alert";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

type Props = {
  modalTitle: string;
  isModalOpen: boolean;
  setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  agenciesList: AgencyMenuItem[];
  selectedAgencyCode: string;
  setSelectedAgencyCode: React.Dispatch<React.SetStateAction<string>>;
  onDownloadCsvClick: () => Promise<void>;
};

/**
 * 代理店モーダル
 * @param props
 * @returns
 */
const AgencyUsersCsvModal: FC<Props> = ({
  modalTitle,
  isModalOpen,
  setIsModalOpen,
  agenciesList,
  selectedAgencyCode,
  setSelectedAgencyCode,
  onDownloadCsvClick,
}) => {
  const [isAlert, setIsAlert] = useState(false);

  return (
    <Modal isOpen={isModalOpen}>
      <div>
        <h3 className="text-2xl font-semibold leading-6 border-b border-gray-300 text-text pb-2">
          {modalTitle}
        </h3>
      </div>
      <div className="">
        <div className="mt-4">
          <dl>
            <div className="flex items-center justify-center w-full gap-5">
              <FormControl sx={{ width: "100%" }}>
                <Select
                  defaultValue={selectedAgencyCode}
                  value={selectedAgencyCode}
                  onChange={(e) =>
                    setSelectedAgencyCode(e.target.value as string)
                  }
                  displayEmpty
                  className="text-left"
                  inputProps={{ "aria-label": "Without label" }}
                >
                  {agenciesList.map((agency: AgencyMenuItem) => (
                    <MenuItem key={agency.code} value={agency.code}>
                      {agency.code !== "" && "[" + agency.code + "] "}
                      {agency.name?.length > 20
                        ? `${agency.name.slice(0, 20)}...`
                        : agency.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
          </dl>
        </div>
        <div className="mt-6 grid grid-flow-row-dense grid-cols-3 gap-60">
          <Button
            onClick={() => {
              setIsModalOpen(!isModalOpen);
              setSelectedAgencyCode("");
            }}
            disabled={!isModalOpen}
            buttonType={"normal"}
          >
            閉じる
          </Button>
          <Button
            onClick={() => {
              onDownloadCsvClick();
              setIsAlert(!isAlert);
            }}
          >
            ダウンロード
          </Button>
        </div>
        <Alert
          isAlert={isAlert}
          setIsAlert={setIsAlert}
          onClick={async () => {}}
          title={"CSVダウンロード"}
          submitText={""}
          cancelText={"閉じる"}
          message={""}
          setMessage={() => {}}
        >
          代理店ユーザーCSVをダウンロードしました。
        </Alert>
      </div>
    </Modal>
  );
};

export default AgencyUsersCsvModal;
