import { API } from "aws-amplify";
import { API_NAME } from "../common/constants";

export const getGenerateUsersCsv = async (agencyCode?: string) => {
  let path;
  if (agencyCode) {
    path = `/agencies/generate_users_csv?agency_code=${agencyCode}`;
  } else {
    path = "/agencies/generate_users_csv";
  }
  const params = {};
  let result: any;
  await API.get(API_NAME, path, params)
    .then((response) => {
      result = response;
    })
    .catch((e) => {
      console.error(e);
      result = e.response.data;
    });
  return result;
};
