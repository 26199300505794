import { useContext, useState } from "react";
import AgencyModal from "../components/agency/AgencyModal";
import AgencyTable from "../components/agency/AgencyTable";
import AgencyUserModal from "../components/agency/AgencyUserModal";
import AgencyUsersCsvModal from "../components/agency/AgencyUsersCsvModal";
import Button from "../components/common/Button";
import LoadingSpinner from "../components/common/LoadingSpinner";
import Page from "../components/common/Pagenation";
import SearchModule from "../components/common/SearchModule";
import { IsAdminContext } from "../components/contexts/IsAdminContext";
import { useAgency } from "../hooks/useAgency";
import { AgencyIcon } from "../components/Icon";
import { useDBUserContext } from "../components/contexts/DBUserContext";

/**
 * 代理店情報画面
 * @returns
 */

const Agencies = () => {
  const { isAdmin } = useContext(IsAdminContext);
  const { dbUser } = useDBUserContext();
  const {
    agencyDataList,
    nextOffset,
    previousOffset,
    isLoading,
    isModalOpen,
    setIsModalOpen,
    isModalLoading,
    modalOpen,
    userModalOpen,
    getAgencyList,
    message,
    setMessage,
    selectedItem,
    setSelectedItem,
    inputKeyword,
    setInputKeyword,
    searchItems,
    // ユーザー追加モーダル
    usersListData,
    isUserModalOpen,
    setIsUserModalOpen,
    isUserModalLoading,
    userEmailValue,
    setUserEmailValue,
    onRegisterUserClick,
    onUpdateUserClick,
    // ユーザー詳細モーダル
    selectedAgencyData,
    agencyCode,
    setAgencyCode,
    agencyName,
    setAgencyName,
    registrationNumber,
    setRegistrationNumber,
    setIsTradingStart,
    setIsModalReload,
    onRegisterAgencyClick,
    onUpdateAgencyClick,
    onUpdateAgencyTradingStatus,
    resetPassword,
    // 代理店ユーザー出力モーダル
    agenciesList,
    isAgencyUsersModal,
    setIsAgencyUsersModal,
    selectedAgencyCode,
    setSelectedAgencyCode,
    downloadUsersListCsv,
  } = useAgency();

  const [isNewAgency, setIsNewAgency] = useState(false);
  const newAgencyModalOpen = () => {
    setIsNewAgency(true);
    modalOpen(null);
  };

  return (
    <>
      <div className="flex flex-col pt-24">
        <div className="flex items-center pb-10">
          <AgencyIcon />
          <h2 className="text-2xl font-semibold leading-6 ml-2">代理店情報</h2>
          {isAdmin && (
            <div className="flex flex-grow justify-end items-center">
              <SearchModule
                onClick={() => getAgencyList(0, selectedItem, inputKeyword)}
                selectedItem={selectedItem}
                setSelectedItem={setSelectedItem}
                inputKeyword={inputKeyword}
                setInputKeyword={setInputKeyword}
                searchItems={searchItems}
              />
              {dbUser.user_id && dbUser.permissions !== 2 && (
                <div className="flex">
                  <div className="mr-3">
                    <Button
                      onClick={() => newAgencyModalOpen()}
                      disabled={isModalOpen}
                    >
                      新規登録
                    </Button>
                  </div>
                  <div>
                    <Button
                      onClick={() => setIsAgencyUsersModal(true)}
                      // disabled={isModalOpen}
                      buttonType={"delete"}
                    >
                      ユーザー出力
                    </Button>
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
      {isLoading ? (
        <div className="mt-20">
          <LoadingSpinner />
        </div>
      ) : (
        <>
          <AgencyModal
            modalTitle="代理店詳細"
            selectedAgencyData={selectedAgencyData}
            isModalOpen={isModalOpen}
            setIsOpen={setIsModalOpen}
            isModalLoading={isModalLoading}
            agencyCode={agencyCode}
            setAgencyCode={setAgencyCode}
            agencyName={agencyName}
            setAgencyName={setAgencyName}
            registrationNumber={registrationNumber}
            setRegistrationNumber={setRegistrationNumber}
            setIsTradingStart={setIsTradingStart}
            setIsModalReload={setIsModalReload}
            onUpdateAgencyClick={onUpdateAgencyClick}
            onUpdateAgencyTradingStatus={onUpdateAgencyTradingStatus}
            isNewAgency={isNewAgency}
            onRegisterAgencyClick={onRegisterAgencyClick}
            message={message}
            setMessage={setMessage}
          />
          <AgencyUserModal
            modalTitle={"代理店ユーザー登録"}
            isUserModalOpen={isUserModalOpen}
            setIsOpen={setIsUserModalOpen}
            isUserModalLoading={isUserModalLoading}
            usersListData={usersListData}
            message={message}
            setMessage={setMessage}
            userEmailValue={userEmailValue}
            setUserEmailValue={setUserEmailValue}
            onRegisterUserClick={onRegisterUserClick}
            onUpdateUserClick={onUpdateUserClick}
            resetPassword={resetPassword}
          />
          <AgencyUsersCsvModal
            modalTitle={"代理店ユーザー情報ダウンロード"}
            isModalOpen={isAgencyUsersModal}
            setIsModalOpen={setIsAgencyUsersModal}
            agenciesList={agenciesList}
            selectedAgencyCode={selectedAgencyCode}
            setSelectedAgencyCode={setSelectedAgencyCode}
            onDownloadCsvClick={downloadUsersListCsv}
          />
          <AgencyTable
            agencyDataList={agencyDataList}
            isModalOpen={isModalOpen}
            modalOpen={modalOpen}
            userModalOpen={userModalOpen}
            setIsNewAgency={setIsNewAgency}
          />
          {isAdmin && (
            <Page
              onClick={getAgencyList}
              previousOffset={previousOffset}
              nextOffset={nextOffset}
              selectedItem={selectedItem}
              keyword={inputKeyword}
            />
          )}
        </>
      )}
    </>
  );
};

export default Agencies;
